<template>
    <div id="chartContainer" class="p-2" style="width: 100%"></div>
</template>

<script setup lang="ts">
import { ref, watch, toRefs }       from 'vue';
import { getOrCreateDataObject, type DataObject, type DataItemModel } from "o365-dataobject";


const props = defineProps<{
    ids: Array<any>,
    mode: number
}>();

const { ids, mode } = toRefs(props);
const chartData = ref<Array<Object>>([]);
const budgetData = ref<Array<number>>([]);
let chart: Highcharts.Chart | null = null;

const local_ProjectsForecasts: DataObject = getOrCreateDataObject({
    id: 'dsProjectsForecasts',
    viewName: 'aviw_Portfolio_PortfolioForecastChart',
    maxRecords: -1,
    whereClause:  ``,
    distinctRows: false,
    selectFirstRowOnLoad: true,
    fields:
    [
        { name: "NameAndTitle" },
        // { name: "DefinitionModule" },
        { name: "UseCostData" },
        { name: "Year1" },
        { name: "Year2" },
        { name: "Year3" },
        { name: "Year4" },
        { name: "Year5" },
        { name: "Year6" },
        { name: "Year7" },
        { name: "Year8" },
        { name: "Year9" },
        { name: "Year10" },
        { name: "Year11" },
        { name: "Year12" },
        { name: "Year13" },
        { name: "Year14" },
        { name: "Year15" },
        { name: "Year16" },
        { name: "Year17" },
        { name: "Year18" },
        { name: "Year19" },
        { name: "Year20" },
        { name: "Year21" },
        { name: "Year22" },
        { name: "Year23" },
        { name: "Year24" },
        { name: "Year25" },
        { name: "Year26" },
        { name: "Year27" },
        { name: "Year28" },
        { name: "Year29" },
        { name: "Year30" },
    ]
});

const local_budget: DataObject = getOrCreateDataObject({
    id: 'dsBudgetChart',
    viewName: 'aviw_Portfolio_YearlyBudgets',
    maxRecords: -1,
    whereClause:  ``,
    distinctRows: false,
    selectFirstRowOnLoad: true,
    fields:
    [
        { name: "OrgUnit_ID" },
        { name: "Year", },
        { name: "ForecastValuePeriodic" },
        { name: "PeriodStart" },
        { name: "PeriodEnd" }
    ]
});

local_budget.on('DataLoaded', (data: DataObject, options) => {
    budgetData.value = [];
    data.forEach((year: DataItemModel) => {
        budgetData.value.push(year.ForecastValuePeriodic);
    })
});

local_budget.enableContextFilter((_, contextID)=>{
    return `[OrgUnit_ID] = '${contextID}' AND [Year] >= ${new Date().getFullYear()}`;
});

local_budget.load();


const getXBudget = (x) => {
    return budgetData.value[x];
}

const createChart = () => {
  const mergedOptions = {
            chart: {
                type: 'column',
                zoomType: 'xy',
                animation: {
                    duration: 1000,
                    easing: 'easeOutBounce'
                },
                events: {
                    beforePrint: function () {
                        const height = this.options.exporting.chartOptions.chart.height;
                        if (height) {
                            this.resetParams = [
                                this.chartWidth,
                                this.chartHeight,
                                false
                                ];
                            this.setSize(this.chartWidth, height, false);
                        }
                    },
                    afterPrint: function () {
                        if (this.options.exporting.chartOptions.chart.height) {
                            this.setSize.apply(this, this.resetParams);
                        }
                    }
                }
            },

            title: {
                text: '',
            },

            yAxis: {
                title: {
                    text: $t('Budget (millions)')
                },
                
                labels: {
                    formatter: function () {
                        return this.value / 1000000 + ' M';
                    }
                },
                stackLabels: {
                    enabled: localStorage.getItem('chartStackLabels') ? JSON.parse(localStorage.getItem('chartStackLabels')) : false,

                    formatter: function () {
                        return Highcharts.numberFormat(this.total, 0); // Format numbers to no decimal places
                    }
                }
            },

            xAxis: {
                allowDecimals: false,
                labels: {
                    formatter: function () {
                        return this.value; // clean, unformatted number for year
                    }
                },
                accessibility: {
                    rangeDescription: 'Years'
                }
            },

            legend: {
                enabled: false,
                layout: 'vertical',
                backgroundColor: '#FFFFFF',
                align: 'right',
                verticalAlign: 'top',
                floating: true,
                y: 45,
                navigation: {
                    activeColor: '#3E576F',
                    animation: true,
                    arrowSize: 12,
                    inactiveColor: '#CCC',
                    style: {
                        fontWeight: 'bold',
                        color: '#333',
                        fontSize: '12px'
                    }
                }
            },

            colors: ['#F15C80', '#8085E9', '#F7A35C', '#80A35C', '#2B908F', '#e1e1ef', '#983422', '#a32020'],

            plotOptions: {
                series: {
                    pointStart: new Date().getFullYear()
                },
                column: {
                    stacking: 'normal',
                    marker: {
                        enabled: false,
                        symbol: 'circle',
                        radius: 2,
                        states: {
                            hover: {
                                enabled: true
                            }
                        }
                    }
                },
            },
            exporting: {
                buttons: {
                    contextButton: {
                        menuItems: ["viewFullscreen", {
                            text: 'Toggle Legends',
                            onclick: function () {
                                var legend = chart.legend;
                                if (legend.display) {
                                    // Hide the legend
                                    chart.legend.update({enabled: false});
                                    localStorage.setItem('chartLegends', JSON.stringify(false))

                                } else {
                                    // Show the legend
                                    chart.legend.update({enabled: true});
                                    localStorage.setItem('chartLegends', JSON.stringify(true))
                                }
                            }
                        }, {
                            text: 'Toggle Stack Labels',
                            onclick: function () {
                                var current = this.yAxis[0].options.stackLabels.enabled;
                                this.update({
                                    yAxis: {
                                        stackLabels: {
                                            enabled: !current
                                        }
                                    }
                                });
                                localStorage.setItem('chartStackLabels', JSON.stringify(!current))
                            }
                        }, "printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF"]
                    },

                }
            },
            
            credits: {
                enabled: false
            },

            series: chartData.value,

            tooltip: {                
                formatter: function() {
                    const totalBudget: number = getXBudget(this.point.index) as number;
                    const remainingBudget: number = totalBudget - (this.total as number);

                    if (this.point.series.name == $t('Budget')) {
                        return `
                            <b>${$t('Budget')} ${this.x}:</b> ${Highcharts.numberFormat(totalBudget, 0)}<br/>
                        `;
                    }

                    if(this.point.series.name == $t('Forecast Cost Projects')) {
                        return `
                            <b>${$t('Forecast Cost Projects')} ${this.x}:</b> ${Highcharts.numberFormat(this.y, 0)}<br/>
                        `;
                    }
                    
                    return `
                        <b> ${this.series.name}</b><br/>
                        <b>${this.x}:</b> ${Highcharts.numberFormat(this.y, 0)} <br/>
                        <br/>
                        <b>${$t('Budget for')} ${this.x}:</b> ${Highcharts.numberFormat(totalBudget, 0)}<br/>
                        <b>${$t('Allocated')}:</b> ${Highcharts.numberFormat(this.total, 0)}<br/>
                        <b>${$t('Remaining')}:</b> ${Highcharts.numberFormat(remainingBudget, 0)}
                    `;
                }
            },

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            }
  };

    chart = Highcharts.chart('chartContainer', mergedOptions);
};

const updateChart = () => {
    if (chart) {
        chart.destroy();
    }

    createChart();
};

// Watch for changes to the ids prop
watch(ids, async (newValue, oldValue) => {
    // Do something with the new value, if needed
    console.log(ids.value)
    if(newValue.length > 0){
        local_ProjectsForecasts.recordSource.whereClause = 'OrgUnit_ID IN (' + newValue.join(', ') + ')';
        await local_ProjectsForecasts.load();
        createDataSet()
    } else {
        local_ProjectsForecasts.recordSource.whereClause = '1=0';
        await local_ProjectsForecasts.load();
        createDataSet()
    }
});

const createDataSet = () => {
    let vData: DataObject = local_ProjectsForecasts.data;

    // Initialize the yearSums array with zeros up to mode.value length
    const yearSums = new Array(mode.value).fill(0);

    chartData.value = vData.map(project => {
        const data = [];
        for (let i = 1; i <= mode.value; i++) {
            const yearProperty = `Year${i}`;
            if (project.hasOwnProperty(yearProperty)) {
                data.push(project[yearProperty]);

                // Check if the project uses cost data
                if (project.UseCostData) {
                    yearSums[i - 1] += project[yearProperty];
                }
            }
        }

        return {
            type: 'column',
            name: project.NameAndTitle,
            data,
            color: project.UseCostData ? "#444444" : ""
        };
    });

    // Sorting logic remains the same
    chartData.value.sort((a, b) => {
        if (a.color === "#444444" && b.color !== "#444444") {
            return 1; // Cost projects last
        } else if (a.color !== "#444444" && b.color === "#444444") {
            return -1; // Cost projects last
        }
        return 0;
    });

    // Add cumulative cost line
    if (yearSums.some(value => value !== 0)) {
        chartData.value.push({
            type: 'line',
            name: $t('Forecast Cost Projects'),
            data: yearSums,
            color: '#33cc33'
        });
    }

    // Add budget line if data exists
    if (budgetData.value.length > 0) {
        chartData.value.push({
            type: 'line',
            name: $t('Budget'),
            data: budgetData.value,
            color: '#f7202c'
        });
    }

    // Update the chart visualization
    updateChart();
};

</script>